function showModal() {
  document.getElementById('instagram-modal').classList.toggle('show');
}

// Select the node that will be observed for mutations
var targetNode = document.getElementById('mce-success-response');

// Options for the observer (which mutations to observe)
var config = { attributes: true, childList: true };

// Callback function to execute when mutations are observed
var callback = function(mutationsList) {
    for(var mutation of mutationsList) {
        if (mutation.type == 'childList') {
            // console.log('A child node has been added or removed.');
            showModal();
        }
        else if (mutation.type == 'attributes') {
            // console.log('The ' + mutation.attributeName + ' attribute was modified.');
        }
    }
};

// Create an observer instance linked to the callback function
var observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);

function addListeners() {
    document.getElementById("modal-close").addEventListener("click", function() {
        document.getElementById("instagram-modal").classList.toggle("show");
    });
}

document.addEventListener("DOMContentLoaded", function() {
    addListeners();
})
